import React from 'react';
import BeforeAfterSlider from '../BeforeAfterSlider';

interface BeforeAfterPreviewProps {
  beforeImage: string;
  afterImage: string;
}

export default function BeforeAfterPreview({ beforeImage, afterImage }: BeforeAfterPreviewProps) {
  return (
    <div className="max-w-2xl mx-auto">
      <BeforeAfterSlider
        beforeImage={beforeImage}
        afterImage={afterImage}
        beforeLabel="Avant"
        afterLabel="Après"
      />
    </div>
  );
}