import React, { useEffect, useState } from 'react';
import { Message } from '../utils/exportConversation';
import ExportButton from './ExportButton';

export default function ConversationHistory() {
  const [messages, setMessages] = useState<Message[]>([
    {
      role: 'user',
      content: "Bon, on va laisser comme c'est actuellement temps que le site n'est pas intégralement terminé.",
      timestamp: new Date().toISOString()
    },
    {
      role: 'assistant',
      content: "D'accord, je comprends. Le site restera dans son état actuel en attendant la finalisation complète.",
      timestamp: new Date().toISOString()
    },
    // Ajoutez ici le reste de notre conversation
  ]);

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">Historique de la conversation</h1>
        <ExportButton messages={messages} />
      </div>
      
      <div className="space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`p-4 rounded-lg ${
              message.role === 'user' 
                ? 'bg-indigo-50 ml-8' 
                : 'bg-gray-50 mr-8'
            }`}
          >
            <div className="font-medium text-gray-900 mb-1">
              {message.role === 'user' ? 'Vous' : 'Assistant'}
            </div>
            <div className="text-gray-700">{message.content}</div>
            <div className="text-xs text-gray-500 mt-2">
              {new Date(message.timestamp).toLocaleString()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}