export interface Message {
  role: 'user' | 'assistant';
  content: string;
  timestamp: string;
}

export function exportConversation(messages: Message[]): string {
  const conversation = {
    metadata: {
      exportDate: new Date().toISOString(),
      totalMessages: messages.length
    },
    messages: messages
  };

  return JSON.stringify(conversation, null, 2);
}

export function downloadConversation(messages: Message[]) {
  const jsonContent = exportConversation(messages);
  const blob = new Blob([jsonContent], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  
  const link = document.createElement('a');
  link.href = url;
  link.download = `conversation-${new Date().toISOString()}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}