import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Layout from './components/Layout';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Galleries from './pages/Galleries';
import GalleryCategory from './pages/GalleryCategory';
import Friends from './pages/Friends';
import Press from './pages/Press';
import AdminLogin from './pages/Admin/Login';
import AdminDashboard from './pages/Admin/Dashboard';
import ConversationHistory from './components/ConversationHistory';
import { useAuthStore } from './store/authStore';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  
  if (!isAuthenticated) {
    return <Navigate to="/admin" replace />;
  }

  return <>{children}</>;
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {/* Route pour l'historique des conversations */}
          <Route path="/conversation" element={<ConversationHistory />} />

          {/* Routes Admin */}
          <Route path="/admin">
            <Route index element={<AdminLogin />} />
            <Route 
              path="dashboard/*" 
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              } 
            />
          </Route>

          {/* Routes publiques */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="galeries">
              <Route index element={<Galleries />} />
              <Route path="infographies" element={<GalleryCategory category="infographies" />} />
              <Route path="dessins" element={<GalleryCategory category="dessins" />} />
              <Route path="peintures" element={<GalleryCategory category="peintures" />} />
              <Route path="photos" element={<GalleryCategory category="photos" />} />
              <Route path="travaux-pros" element={<GalleryCategory category="travaux-pros" />} />
              <Route path="immobilier" element={<GalleryCategory category="immobilier" />} />
            </Route>
            <Route path="amis" element={<Friends />} />
            <Route path="presse" element={<Press />} />
            <Route path="contact" element={<Contact />} />
          </Route>

          {/* Route par défaut pour les URLs inconnues */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}