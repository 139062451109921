import React, { useState } from 'react';
import { Folder, Trash2, Edit2, Save, X } from 'lucide-react';
import { PhotoFolder } from '../../types/folder';

interface FoldersListProps {
  folders: PhotoFolder[];
  onDelete: (id: number) => void;
  onEdit: (id: number, name: string, description: string) => Promise<void>;
}

export default function FoldersList({ folders, onDelete, onEdit }: FoldersListProps) {
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editName, setEditName] = useState('');
  const [editDescription, setEditDescription] = useState('');

  const handleEditClick = (folder: PhotoFolder) => {
    setEditingId(folder.id);
    setEditName(folder.name);
    setEditDescription(folder.description || '');
  };

  const handleSave = async (id: number) => {
    try {
      await onEdit(id, editName, editDescription);
      setEditingId(null);
    } catch (error) {
      console.error('Error saving folder:', error);
    }
  };

  if (folders.length === 0) {
    return (
      <div className="text-center py-12 text-gray-400">
        Aucun dossier n'a été créé pour le moment
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {folders.map((folder) => (
        <div
          key={folder.id}
          className="bg-gray-700/50 rounded-lg p-4 flex items-center justify-between group hover:bg-gray-700/70 transition-colors"
        >
          {editingId === folder.id ? (
            <div className="flex-1 mr-4">
              <input
                type="text"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                className="w-full mb-2 rounded-md bg-gray-600 border-gray-500 text-white"
                placeholder="Nom du dossier"
              />
              <textarea
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
                className="w-full rounded-md bg-gray-600 border-gray-500 text-white"
                placeholder="Description (optionnelle)"
                rows={2}
              />
            </div>
          ) : (
            <div className="flex items-center space-x-3">
              <Folder className="w-5 h-5 text-blue-400" />
              <div>
                <h3 className="text-white font-medium">{folder.name}</h3>
                {folder.description && (
                  <p className="text-sm text-gray-400">{folder.description}</p>
                )}
              </div>
            </div>
          )}
          
          <div className="flex space-x-2">
            {editingId === folder.id ? (
              <>
                <button
                  onClick={() => handleSave(folder.id)}
                  className="p-2 text-green-400 hover:text-green-300 transition-colors"
                  title="Enregistrer"
                >
                  <Save className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setEditingId(null)}
                  className="p-2 text-gray-400 hover:text-gray-300 transition-colors"
                  title="Annuler"
                >
                  <X className="w-5 h-5" />
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => handleEditClick(folder)}
                  className="p-2 text-blue-400 opacity-0 group-hover:opacity-100 transition-opacity hover:text-blue-300"
                  title="Modifier"
                >
                  <Edit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => onDelete(folder.id)}
                  className="p-2 text-red-400 opacity-0 group-hover:opacity-100 transition-opacity hover:text-red-300"
                  title="Supprimer"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}