import axios from 'axios';
import { API_URL } from '../config';
import { PressItem } from '../../types/press';

export const fetchPressItems = async (): Promise<PressItem[]> => {
  try {
    const response = await axios.get(`${API_URL}/press.php`);
    return response.data;
  } catch (error) {
    console.error('Error fetching press items:', error);
    throw error;
  }
};

export const addPressItem = async (formData: FormData): Promise<void> => {
  try {
    await axios.post(`${API_URL}/press.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('Error adding press item:', error);
    throw error;
  }
};

export const editPressItem = async (id: number, formData: FormData): Promise<void> => {
  try {
    await axios.post(`${API_URL}/press.php?id=${id}&_method=PUT`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('Error editing press item:', error);
    throw error;
  }
};

export const deletePressItem = async (id: number): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/press.php?id=${id}`);
  } catch (error) {
    console.error('Error deleting press item:', error);
    throw error;
  }
};