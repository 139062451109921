import React from 'react';

interface CategorySelectorProps {
  selectedCategory: string;
  onCategoryChange: (category: string) => void;
}

const categories = [
  { id: 'all', label: 'Toutes les catégories' },
  { id: 'infographies', label: 'Infographies' },
  { id: 'dessins', label: 'Dessins' },
  { id: 'peintures', label: 'Peintures' },
  { id: 'photos', label: 'Photos' },
  { id: 'travaux-pros', label: 'Travaux pros' },
  { id: 'immobilier', label: 'Immobilier' }
];

export default function CategorySelector({ selectedCategory, onCategoryChange }: CategorySelectorProps) {
  return (
    <div className="mb-6">
      <label htmlFor="category-filter" className="block text-sm font-medium text-gray-200 mb-2">
        Filtrer par catégorie
      </label>
      <select
        id="category-filter"
        value={selectedCategory}
        onChange={(e) => onCategoryChange(e.target.value)}
        className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
      >
        {categories.map((category) => (
          <option 
            key={category.id} 
            value={category.id}
            className="bg-gray-700 text-white"
          >
            {category.label}
          </option>
        ))}
      </select>
    </div>
  );
}