import React from 'react';
import { Link } from 'react-router-dom';
import { Palette, Pencil, PaintBucket, Camera, Briefcase, Home } from 'lucide-react';

const categories = [
  { 
    id: 'infographies', 
    label: 'Infographies', 
    icon: Palette,
    description: 'Créations numériques et designs graphiques'
  },
  { 
    id: 'dessins', 
    label: 'Dessins', 
    icon: Pencil,
    description: 'Croquis et illustrations au crayon'
  },
  { 
    id: 'peintures', 
    label: 'Peintures', 
    icon: PaintBucket,
    description: 'Œuvres réalisées avec différentes techniques picturales'
  },
  { 
    id: 'photos', 
    label: 'Photos', 
    icon: Camera,
    description: 'Photographies artistiques et reportages'
  },
  { 
    id: 'travaux-pros', 
    label: 'Travaux pros', 
    icon: Briefcase,
    description: 'Réalisations professionnelles et projets clients'
  },
  { 
    id: 'immobilier', 
    label: 'Immobilier', 
    icon: Home,
    description: 'Transformations et mises en valeur immobilières'
  }
];

export default function Galleries() {
  return (
    <div className="max-w-7xl mx-auto">
      <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-8 mb-12 border border-white/10">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-white mb-8 flex items-center gap-3">
            <Palette className="w-8 h-8 text-blue-400" />
            Galeries
          </h1>
          
          <div className="prose prose-lg prose-invert max-w-none">
            <p className="text-gray-300 leading-relaxed">
              Aussi loin que je me souvienne, j'ai toujours aimé dessiner. Aujourd'hui, rien n'a changé si ce n'est que je me suis ouvert à d'autres techniques, mais la passion est toujours la même, toujours aussi intense au même titre que ma curiosité. Ainsi, du dessin, j'ai découvert la peinture, l'infographie et plus récemment la photo.
            </p>
            
            <p className="text-gray-300 leading-relaxed">
              J'espère qu'en visitant cette galerie vous n'allez pas avoir trop peur, certaines créations ne sont pas à mettre devant les âmes sensibles. Et oui, j'ai tendance à m'exprimer à travers des créations un peu sombres mais soyez rassuré, d'autres œuvres sont nettement plus optimistes.
            </p>
            
            <p className="text-gray-300 leading-relaxed">
              Si vous voulez en savoir plus, n'hésitez pas à vous rendre sur la page contact, il y a un formulaire et mes coordonnées, ça sera avec plaisir que je vous renseignerai.
            </p>
            
            <p className="text-right italic text-gray-400">
              En attendant, je vous souhaite une agréable visite.<br />
              Thierry
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {categories.map((category) => {
          const Icon = category.icon;
          return (
            <Link
              key={category.id}
              to={`/galeries/${category.id}`}
              className="group bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10 hover:border-blue-500/50 transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/10"
            >
              <div className="flex items-center gap-4 mb-4">
                <div className="p-3 rounded-lg bg-gray-700/50 text-blue-400 group-hover:scale-110 transition-transform duration-300">
                  <Icon className="w-6 h-6" />
                </div>
                <h2 className="text-xl font-semibold text-white group-hover:text-blue-400 transition-colors">
                  {category.label}
                </h2>
              </div>
              <p className="text-gray-400 text-sm leading-relaxed">
                {category.description}
              </p>
            </Link>
          )
        })}
      </div>
    </div>
  );
}