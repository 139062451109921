import React, { useState, useEffect } from 'react';
import { fetchFriends } from '../services/api/friends';
import { Friend } from '../types/friend';
import { Link as LinkIcon } from 'lucide-react';

export default function Friends() {
  const [friends, setFriends] = useState<Friend[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadFriends = async () => {
      try {
        const data = await fetchFriends();
        setFriends(data);
      } catch (err) {
        setError('Erreur lors du chargement des amis');
      } finally {
        setLoading(false);
      }
    };

    loadFriends();
  }, []);

  const handleImageClick = (websiteUrl: string | null) => {
    if (websiteUrl) {
      window.open(websiteUrl, '_blank', 'noopener,noreferrer');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-8">
        {error}
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto">
      <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-8 mb-8">
        <h1 className="text-3xl font-bold text-white mb-6">Liens Amis</h1>
        <p className="text-gray-300 mb-8">
          Découvrez les sites de mes amis et partenaires.
        </p>

        {friends.length === 0 ? (
          <p className="text-center text-gray-400 py-8">
            Aucun ami n'a été ajouté pour le moment.
          </p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {friends.map((friend) => (
              <div
                key={friend.id}
                className="bg-gray-700/50 rounded-lg shadow-md overflow-hidden hover:transform hover:scale-105 transition-transform duration-300"
              >
                <div 
                  className="aspect-w-16 aspect-h-9 cursor-pointer"
                  onClick={() => handleImageClick(friend.website_url)}
                >
                  <img
                    src={`/uploads/${friend.image_path}`}
                    alt={friend.name}
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold text-white">
                      {friend.name}
                    </h2>
                    {friend.website_url && (
                      <a
                        href={friend.website_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-300 transition-colors"
                      >
                        <LinkIcon className="w-5 h-5" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}