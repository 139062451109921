import React, { useState } from 'react';
import { ZoomIn, X } from 'lucide-react';

interface ImageZoomProps {
  src: string;
  alt: string;
}

export default function ImageZoom({ src, alt }: ImageZoomProps) {
  const [isZoomed, setIsZoomed] = useState(false);

  if (!isZoomed) {
    return (
      <button
        onClick={() => setIsZoomed(true)}
        className="absolute top-2 right-2 p-2 bg-black/50 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        title="Zoom"
      >
        <ZoomIn className="w-4 h-4" />
      </button>
    );
  }

  return (
    <div className="fixed inset-0 z-50 bg-black/95 flex items-center justify-center">
      <button
        onClick={() => setIsZoomed(false)}
        className="absolute top-4 right-4 p-2 bg-black/50 text-white rounded-full hover:bg-black/75 transition-colors"
      >
        <X className="w-6 h-6" />
      </button>
      <div className="w-full h-full p-8 flex items-center justify-center">
        <img
          src={src}
          alt={alt}
          className="max-w-full max-h-full object-contain"
        />
      </div>
    </div>
  );
}