import axios from 'axios';
import { Image, ImageCategory } from '../../types/image';
import { API_URL } from '../config';

export async function fetchImages(): Promise<Image[]> {
  try {
    const response = await axios.get<Image[]>(`${API_URL}/images.php`, {
      headers: {
        'Accept': 'application/json',
        'Cache-Control': 'no-cache'
      }
    });

    if (!response.data) {
      throw new Error('No data received from the server');
    }

    if (!Array.isArray(response.data)) {
      throw new Error('Invalid response format');
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(`Network error: ${error.message}`);
    }
    throw error;
  }
}

export async function fetchImagesByCategory(category: ImageCategory): Promise<Image[]> {
  try {
    const images = await fetchImages();
    return images.filter(img => img.category === category);
  } catch (error) {
    console.error(`Error fetching images for category ${category}:`, error);
    throw error;
  }
}

export async function fetchCarouselImages(limit: number = 10): Promise<Image[]> {
  try {
    const images = await fetchImages();
    return images
      .filter(img => img.category === 'dessins' || img.category === 'peintures')
      .sort(() => Math.random() - 0.5)
      .slice(0, limit);
  } catch (error) {
    console.error('Error fetching carousel images:', error);
    throw error;
  }
}