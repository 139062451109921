import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import ImageModal from './ImageModal';

interface ImageGridProps {
  images: Array<{
    id: number;
    title: string;
    file_path: string;
    after_image_path?: string | null;
  }>;
  onImageClick: (imagePath: string, index: number) => void;
}

export default function ImageGrid({ images, onImageClick }: ImageGridProps) {
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const handleImageClick = (index: number) => {
    setSelectedImage(index);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleNext = () => {
    if (selectedImage !== null && selectedImage < images.length - 1) {
      setSelectedImage(selectedImage + 1);
    }
  };

  const handlePrev = () => {
    if (selectedImage !== null && selectedImage > 0) {
      setSelectedImage(selectedImage - 1);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {images.map((image, index) => (
          <div
            key={image.id}
            className="group relative bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md overflow-hidden border border-white/10"
          >
            <div className="relative pt-[100%]">
              <img
                src={`/uploads/${image.file_path}`}
                alt={image.title}
                className="absolute inset-0 w-full h-full object-contain bg-gray-900/50 transition-transform duration-300 group-hover:scale-105"
                loading="lazy"
              />
              <button
                onClick={() => handleImageClick(index)}
                className="absolute inset-0 w-full h-full cursor-zoom-in"
                aria-label="Zoom image"
              />
              <button
                onClick={() => onImageClick(`/uploads/${image.file_path}`, index)}
                className="absolute top-2 left-2 p-2 bg-black/50 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                title="Voir dans le carousel"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
            <div className="absolute bottom-0 left-0 right-0 p-3 bg-gradient-to-t from-black/70 to-transparent translate-y-full group-hover:translate-y-0 transition-transform duration-300">
              <h3 className="text-sm font-medium text-white truncate">
                {image.title}
              </h3>
            </div>
          </div>
        ))}
      </div>

      {selectedImage !== null && (
        <ImageModal
          src={`/uploads/${images[selectedImage].file_path}`}
          alt={images[selectedImage].title}
          onClose={handleClose}
          onNext={handleNext}
          onPrev={handlePrev}
          hasNext={selectedImage < images.length - 1}
          hasPrev={selectedImage > 0}
        />
      )}
    </>
  );
}