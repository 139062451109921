import axios from 'axios';
import { API_URL } from '../config';
import { Friend } from '../../types/friend';

export const fetchFriends = async (): Promise<Friend[]> => {
  try {
    const response = await axios.get(`${API_URL}/friends.php`);
    return response.data;
  } catch (error) {
    console.error('Error fetching friends:', error);
    throw error;
  }
};

export const addFriend = async (formData: FormData): Promise<void> => {
  try {
    await axios.post(`${API_URL}/friends.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    console.error('Error adding friend:', error);
    throw error;
  }
};

export const deleteFriend = async (id: number): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/friends.php?id=${id}`);
  } catch (error) {
    console.error('Error deleting friend:', error);
    throw error;
  }
};