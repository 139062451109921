import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Palette, Users, BookText, Mail, Home, ChevronDown } from 'lucide-react';
import { fetchFolders } from '../services/api/folders';
import { PhotoFolder } from '../types/folder';

const baseCategories = [
  { path: '/galeries/infographies', label: 'Infographies' },
  { path: '/galeries/dessins', label: 'Dessins' },
  { path: '/galeries/peintures', label: 'Peintures' },
  { path: '/galeries/photos', label: 'Photos' },
  { path: '/galeries/travaux-pros', label: 'Travaux pros' },
  { path: '/galeries/immobilier', label: 'Immobilier' }
];

const navItems = [
  { path: '/', label: 'Accueil', icon: Home },
  { 
    path: '/galeries', 
    label: 'Galeries', 
    icon: Palette,
    hasSubmenu: true 
  },
  { path: '/amis', label: 'Liens Amis', icon: Users },
  { path: '/presse', label: 'Revue de Presse', icon: BookText },
  { path: '/contact', label: 'Contact', icon: Mail },
];

export default function Layout() {
  const location = useLocation();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [folders, setFolders] = useState<PhotoFolder[]>([]);
  const [galleryCategories, setGalleryCategories] = useState(baseCategories);
  
  useEffect(() => {
    const loadFolders = async () => {
      try {
        const folderData = await fetchFolders();
        const photoCategories = folderData.map(folder => ({
          path: `/galeries/photos?folder=${folder.id}`,
          label: folder.name,
          isSubItem: true
        }));
        
        // Trouver l'index de la catégorie "Photos"
        const photosIndex = baseCategories.findIndex(cat => cat.path === '/galeries/photos');
        
        // Créer le nouveau tableau de catégories avec les sous-dossiers
        const newCategories = [
          ...baseCategories.slice(0, photosIndex + 1),
          ...photoCategories,
          ...baseCategories.slice(photosIndex + 1)
        ];
        
        setGalleryCategories(newCategories);
      } catch (error) {
        console.error('Erreur lors du chargement des dossiers:', error);
      }
    };
    
    loadFolders();
  }, []);
  
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <header className="bg-black/50 backdrop-blur-sm border-b border-white/10 relative z-50">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex space-x-8">
              {navItems.map(({ path, label, icon: Icon, hasSubmenu }) => (
                <div key={path} className="relative">
                  <Link
                    to={path}
                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium h-16 ${
                      (location.pathname === path || (path === '/galeries' && location.pathname.startsWith('/galeries')))
                        ? 'border-blue-500 text-white'
                        : 'border-transparent text-gray-300 hover:border-gray-300 hover:text-white'
                    }`}
                    onMouseEnter={() => hasSubmenu && setIsGalleryOpen(true)}
                    onMouseLeave={() => hasSubmenu && setIsGalleryOpen(false)}
                  >
                    <Icon className="w-4 h-4 mr-2" />
                    {label}
                    {hasSubmenu && <ChevronDown className="w-4 h-4 ml-1" />}
                  </Link>
                  
                  <AnimatePresence>
                    {hasSubmenu && isGalleryOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="absolute left-0 w-48 bg-gray-800/95 backdrop-blur-sm shadow-xl rounded-b-lg border border-white/10"
                        onMouseEnter={() => setIsGalleryOpen(true)}
                        onMouseLeave={() => setIsGalleryOpen(false)}
                      >
                        {galleryCategories.map((category) => (
                          <Link
                            key={category.path}
                            to={category.path}
                            className={`block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700/50 hover:text-white transition-colors first:rounded-t-lg last:rounded-b-lg ${
                              location.pathname + location.search === category.path
                                ? 'bg-gray-700/50 text-white'
                                : ''
                            } ${category.isSubItem ? 'pl-8' : ''}`}
                          >
                            {category.label}
                          </Link>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
          </div>
        </nav>
      </header>

      <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8 relative z-0">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.3 }}
        >
          <Outlet />
        </motion.div>
      </main>

      <footer className="bg-black/50 backdrop-blur-sm border-t border-white/10 relative z-10">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center space-x-6">
            {navItems.map(({ path, label }) => (
              <Link
                key={path}
                to={path}
                className="text-sm text-gray-400 hover:text-white transition-colors"
              >
                {label}
              </Link>
            ))}
          </div>
          <div className="mt-4 text-center text-sm text-gray-400">
            © {new Date().getFullYear()} Portfolio. Tous droits réservés.
          </div>
        </div>
      </footer>
    </div>
  );
}