import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import ImageModal from '../components/ImageModal';
import BeforeAfterSlider from '../components/BeforeAfterSlider';
import ImageGrid from '../components/ImageGrid';
import { Quote, FolderOpen } from 'lucide-react';
import { fetchFolders } from '../services/api/folders';
import { PhotoFolder } from '../types/folder';

interface GalleryCategoryProps {
  category: string;
}

interface GalleryItem {
  id: number;
  title: string;
  file_path: string;
  after_image_path: string | null;
  category: string;
  folder_id: number | null;
  folder_name?: string | null;
}

const formatCategoryTitle = (category: string) => {
  const translations: { [key: string]: string } = {
    'infographies': 'Infographies',
    'dessins': 'Dessins',
    'peintures': 'Peintures',
    'photos': 'Photos',
    'travaux-pros': 'Travaux Professionnels',
    'immobilier': 'Immobilier'
  };
  return translations[category] || category;
};

export default function GalleryCategory({ category }: GalleryCategoryProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const folderIdFromUrl = searchParams.get('folder');
  
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [images, setImages] = useState<GalleryItem[]>([]);
  const [folders, setFolders] = useState<PhotoFolder[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<number | null>(
    folderIdFromUrl ? parseInt(folderIdFromUrl) : null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [imagesResponse, foldersResponse] = await Promise.all([
          axios.get('/server/api/images.php'),
          category === 'photos' ? fetchFolders() : Promise.resolve([])
        ]);

        const filteredImages = imagesResponse.data.filter(
          (image: GalleryItem) => image.category === category
        );
        setImages(filteredImages);
        
        if (category === 'photos') {
          setFolders(foldersResponse);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [category]);

  useEffect(() => {
    if (folderIdFromUrl) {
      setSelectedFolder(parseInt(folderIdFromUrl));
    } else {
      setSelectedFolder(null);
    }
  }, [folderIdFromUrl, location.search]);

  const filteredImages = images.filter(image => {
    if (category !== 'photos') return true;
    if (selectedFolder === null) return true;
    return image.folder_id === selectedFolder;
  });

  const regularImages = filteredImages.filter(image => !image.after_image_path);
  const beforeAfterImages = filteredImages.filter(image => image.after_image_path);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handleNext = () => {
    if (selectedImageIndex !== null && selectedImageIndex < regularImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedImageIndex !== null && selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-8">
        {error}
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto">
      <h1 className="text-3xl font-bold text-white mb-8">
        {formatCategoryTitle(category)}
      </h1>

      {category === 'photos' && folders.length > 0 && (
        <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <button
            onClick={() => setSelectedFolder(null)}
            className={`flex items-center space-x-3 p-4 rounded-lg transition-colors ${
              selectedFolder === null
                ? 'bg-blue-600 text-white'
                : 'bg-gray-800/95 text-gray-300 hover:bg-gray-700/95'
            }`}
          >
            <FolderOpen className="w-5 h-5" />
            <span>Tous les dossiers</span>
          </button>
          {folders.map(folder => (
            <button
              key={folder.id}
              onClick={() => setSelectedFolder(folder.id)}
              className={`flex items-center space-x-3 p-4 rounded-lg transition-colors ${
                selectedFolder === folder.id
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-800/95 text-gray-300 hover:bg-gray-700/95'
              }`}
            >
              <FolderOpen className="w-5 h-5" />
              <span>{folder.name}</span>
            </button>
          ))}
        </div>
      )}

      {category === 'immobilier' && (
        <div className="bg-gray-800/95 rounded-lg shadow-md p-12 mb-12 relative">
          <Quote className="absolute text-gray-700/20 w-24 h-24 -top-4 -left-4" />
          <Quote className="absolute text-gray-700/20 w-24 h-24 -bottom-4 -right-4 rotate-180" />
          <div className="relative z-10">
            <p className="text-gray-200 text-xl leading-relaxed">
              Fort d'une solide expérience en infographie, j'ai réalisé de nombreux projets dans le secteur de l'immobilier visant à améliorer la présentation visuelle des biens. En effet, les vendeurs ne maîtrisent pas toujours l'art de valoriser leur propriété, et c'est là que j'interviens.
            </p>
            <p className="text-gray-200 text-xl leading-relaxed mt-4">
              Mes services s'adressent aussi bien aux particuliers qu'aux professionnels.
            </p>
          </div>
        </div>
      )}

      {category === 'immobilier' && beforeAfterImages.length > 0 && (
        <div className="mb-12 space-y-8">
          <h2 className="text-2xl font-semibold text-white">Avant / Après</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {beforeAfterImages.map(image => (
              <div key={image.id} className="bg-gray-800/95 rounded-lg shadow-md overflow-hidden">
                <BeforeAfterSlider
                  beforeImage={`/uploads/${image.file_path}`}
                  afterImage={`/uploads/${image.after_image_path}`}
                  beforeLabel="Avant"
                  afterLabel="Après"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-white">{image.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {regularImages.length > 0 ? (
        <ImageGrid
          images={regularImages}
          onImageClick={(_, index) => handleImageClick(index)}
        />
      ) : (
        <div className="text-center py-12 text-gray-400">
          Aucune image dans cette catégorie
        </div>
      )}

      {selectedImageIndex !== null && (
        <ImageModal
          src={`/uploads/${regularImages[selectedImageIndex].file_path}`}
          alt={regularImages[selectedImageIndex].title}
          onClose={() => setSelectedImageIndex(null)}
          onNext={handleNext}
          onPrev={handlePrev}
          hasNext={selectedImageIndex < regularImages.length - 1}
          hasPrev={selectedImageIndex > 0}
        />
      )}
    </div>
  );
}