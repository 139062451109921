import React, { useState, useEffect, useRef } from 'react';
import { X, ZoomIn, ZoomOut, ChevronLeft, ChevronRight } from 'lucide-react';

interface ImageModalProps {
  src: string;
  alt: string;
  onClose: () => void;
  onNext?: () => void;
  onPrev?: () => void;
  hasNext?: boolean;
  hasPrev?: boolean;
}

interface Velocity {
  x: number;
  y: number;
}

export default function ImageModal({
  src,
  alt,
  onClose,
  onNext,
  onPrev,
  hasNext,
  hasPrev
}: ImageModalProps) {
  const [scale, setScale] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [velocity, setVelocity] = useState<Velocity>({ x: 0, y: 0 });
  const modalRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const animationRef = useRef<number>();
  const lastTimeRef = useRef<number>(0);
  const lastMousePosRef = useRef({ x: 0, y: 0 });

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();
    const delta = e.deltaY * -0.01;
    const newScale = Math.min(Math.max(scale + delta, 0.5), 5);
    setScale(newScale);
    setIsZoomed(newScale > 1);
  };

  const getBoundaries = () => {
    if (!imageRef.current || !modalRef.current) return { maxX: 0, maxY: 0 };
    const containerRect = modalRef.current.getBoundingClientRect();
    const scaledWidth = imageRef.current.width * scale;
    const scaledHeight = imageRef.current.height * scale;
    
    return {
      maxX: Math.max((scaledWidth - containerRect.width) / 2, 0),
      maxY: Math.max((scaledHeight - containerRect.height) / 2, 0)
    };
  };

  const applyBoundaries = (pos: { x: number, y: number }) => {
    const { maxX, maxY } = getBoundaries();
    return {
      x: Math.min(Math.max(pos.x, -maxX), maxX),
      y: Math.min(Math.max(pos.y, -maxY), maxY)
    };
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (scale > 1) {
      setIsDragging(true);
      setDragStart({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
      lastMousePosRef.current = { x: e.clientX, y: e.clientY };
      lastTimeRef.current = Date.now();
      setVelocity({ x: 0, y: 0 });
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging && scale > 1) {
      const currentTime = Date.now();
      const deltaTime = currentTime - lastTimeRef.current;
      
      if (deltaTime > 0) {
        const newX = e.clientX - dragStart.x;
        const newY = e.clientY - dragStart.y;
        
        const velocityX = (e.clientX - lastMousePosRef.current.x) / deltaTime;
        const velocityY = (e.clientY - lastMousePosRef.current.y) / deltaTime;
        
        setVelocity({ x: velocityX, y: velocityY });
        const boundedPosition = applyBoundaries({ x: newX, y: newY });
        setPosition(boundedPosition);
        
        lastMousePosRef.current = { x: e.clientX, y: e.clientY };
        lastTimeRef.current = currentTime;
      }
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      setLastPosition(position);
      
      if (Math.abs(velocity.x) > 0.1 || Math.abs(velocity.y) > 0.1) {
        animateInertia();
      }
    }
  };

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === modalRef.current) {
      onClose();
    }
  };

  const animateInertia = () => {
    const friction = 0.95;
    let currentVelocity = { ...velocity };
    
    const animate = () => {
      if (Math.abs(currentVelocity.x) < 0.01 && Math.abs(currentVelocity.y) < 0.01) {
        return;
      }

      currentVelocity = {
        x: currentVelocity.x * friction,
        y: currentVelocity.y * friction
      };

      const newPosition = {
        x: position.x + currentVelocity.x * 16,
        y: position.y + currentVelocity.y * 16
      };

      const boundedPosition = applyBoundaries(newPosition);
      setPosition(boundedPosition);

      if (boundedPosition.x !== newPosition.x || boundedPosition.y !== newPosition.y) {
        return;
      }

      animationRef.current = requestAnimationFrame(animate);
    };

    animate();
  };

  const handleZoomIn = () => {
    const newScale = Math.min(scale + 0.25, 5);
    setScale(newScale);
    setIsZoomed(newScale > 1);
  };

  const handleZoomOut = () => {
    const newScale = Math.max(scale - 0.25, 0.5);
    setScale(newScale);
    setIsZoomed(newScale > 1);
    if (newScale <= 1) {
      setPosition({ x: 0, y: 0 });
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        onClose();
        break;
      case 'ArrowLeft':
        if (hasPrev && onPrev) onPrev();
        break;
      case 'ArrowRight':
        if (hasNext && onNext) onNext();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const container = modalRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
    }
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
      window.removeEventListener('keydown', handleKeyDown);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [onNext, onPrev, position, velocity]);

  useEffect(() => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
    setIsZoomed(false);
    setVelocity({ x: 0, y: 0 });
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  }, [src]);

  return (
    <div 
      ref={modalRef}
      className="fixed inset-0 bg-black/95 z-50 flex items-center justify-center cursor-zoom-in"
      onClick={handleBackgroundClick}
    >
      <div 
        className="relative w-full h-full flex items-center justify-center overflow-hidden"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{ cursor: isDragging ? 'grabbing' : scale > 1 ? 'grab' : 'zoom-in' }}
      >
        {hasPrev && onPrev && (
          <button
            onClick={onPrev}
            className="absolute left-4 text-white hover:text-gray-300 bg-black/50 rounded-full p-3 transition-colors duration-200 z-20"
          >
            <ChevronLeft className="w-8 h-8" />
          </button>
        )}

        <div className="relative max-w-[90vw] max-h-[90vh]">
          <img
            ref={imageRef}
            src={src}
            alt={alt}
            className="max-w-full max-h-[90vh] object-contain will-change-transform"
            style={{ 
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
              transformOrigin: 'center',
              userSelect: 'none',
              transition: isDragging ? 'none' : 'transform 0.1s ease-out'
            }}
            draggable={false}
          />
          
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center space-x-4">
            <button
              onClick={handleZoomOut}
              className="text-white hover:text-gray-300 bg-black/50 rounded-full p-2 transition-colors duration-200"
              disabled={scale <= 0.5}
            >
              <ZoomOut className="w-6 h-6" />
            </button>
            
            <button
              onClick={onClose}
              className="text-white hover:text-gray-300 bg-black/50 rounded-full p-2 transition-colors duration-200"
            >
              <X className="w-6 h-6" />
            </button>
            
            <button
              onClick={handleZoomIn}
              className="text-white hover:text-gray-300 bg-black/50 rounded-full p-2 transition-colors duration-200"
              disabled={scale >= 5}
            >
              <ZoomIn className="w-6 h-6" />
            </button>
          </div>
        </div>

        {hasNext && onNext && (
          <button
            onClick={onNext}
            className="absolute right-4 text-white hover:text-gray-300 bg-black/50 rounded-full p-3 transition-colors duration-200 z-20"
          >
            <ChevronRight className="w-8 h-8" />
          </button>
        )}
      </div>
    </div>
  );
}