import React, { useState, useEffect } from 'react';
import { fetchPressItems } from '../services/api/press';
import { PressItem } from '../types/press';
import { Newspaper, Calendar, ExternalLink, Book, LayoutGrid } from 'lucide-react';
import ImageZoom from '../components/ImageZoom';
import PressFlipBook from '../components/PressFlipBook';

export default function Press() {
  const [pressItems, setPressItems] = useState<PressItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'book'>('book');

  useEffect(() => {
    const loadPressItems = async () => {
      try {
        const data = await fetchPressItems();
        setPressItems(data);
      } catch (err) {
        setError('Erreur lors du chargement des articles de presse');
      } finally {
        setLoading(false);
      }
    };

    loadPressItems();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 py-8">
        {error}
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto">
      <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-8">
        <div className="flex items-center justify-between gap-4 mb-12">
          <div className="flex items-center gap-4">
            <div className="bg-blue-500/10 rounded-full p-3">
              <Newspaper className="w-8 h-8 text-blue-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold text-white">Revue de Presse</h1>
              <p className="text-gray-400 mt-1">Découvrez les articles et mentions dans la presse</p>
            </div>
          </div>
          
          <div className="flex gap-2">
            <button
              onClick={() => setViewMode('book')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'book' 
                  ? 'bg-blue-500/20 text-blue-400' 
                  : 'text-gray-400 hover:text-white'
              }`}
              title="Vue catalogue"
            >
              <Book className="w-5 h-5" />
            </button>
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2 rounded-lg transition-colors ${
                viewMode === 'grid' 
                  ? 'bg-blue-500/20 text-blue-400' 
                  : 'text-gray-400 hover:text-white'
              }`}
              title="Vue grille"
            >
              <LayoutGrid className="w-5 h-5" />
            </button>
          </div>
        </div>

        {pressItems.length === 0 ? (
          <p className="text-center text-gray-400 py-8">
            Aucun article de presse n'est disponible pour le moment.
          </p>
        ) : viewMode === 'book' ? (
          <PressFlipBook pressItems={pressItems} />
        ) : (
          <div className="space-y-12">
            {pressItems.map((item) => (
              <div
                key={item.id}
                className="bg-gray-700/30 rounded-lg shadow-xl overflow-hidden border border-white/5 hover:border-white/10 transition-all duration-300 group"
              >
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                  <div className="relative p-6 lg:p-8">
                    <div className="relative aspect-[4/3] rounded-lg overflow-hidden bg-black/20">
                      <img
                        src={`/uploads/${item.image_path}`}
                        alt={item.title}
                        className="w-full h-full object-contain"
                      />
                      <ImageZoom src={`/uploads/${item.image_path}`} alt={item.title} />
                    </div>
                  </div>
                  
                  <div className="p-6 lg:p-8 lg:pr-12 flex flex-col justify-between">
                    <div>
                      <div className="flex items-center gap-2 text-blue-400 mb-4">
                        <Calendar className="w-4 h-4" />
                        <time className="text-sm">
                          {new Date(item.date).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                        </time>
                      </div>
                      
                      <h2 className="text-2xl font-bold text-white mb-4 group-hover:text-blue-400 transition-colors">
                        {item.title}
                      </h2>
                      
                      <div className="prose prose-invert prose-sm max-w-none">
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                      </div>
                    </div>
                    
                    <div className="mt-6 flex justify-end">
                      <button className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 transition-colors">
                        <span className="text-sm font-medium">Lire l'article complet</span>
                        <ExternalLink className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}