import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import ImageUploadForm from '../../components/admin/ImageUploadForm';
import ImageGallery from '../../components/admin/ImageGallery';
import CategorySelector from '../../components/admin/CategorySelector';
import FriendForm from '../../components/admin/FriendForm';
import FriendsList from '../../components/admin/FriendsList';
import PressForm from '../../components/admin/PressForm';
import PressList from '../../components/admin/PressList';
import FolderForm from '../../components/admin/FolderForm';
import FoldersList from '../../components/admin/FoldersList';
import { fetchFriends, addFriend, deleteFriend } from '../../services/api/friends';
import { fetchPressItems, addPressItem, editPressItem, deletePressItem } from '../../services/api/press';
import { fetchFolders, createFolder, editFolder, deleteFolder } from '../../services/api/folders';
import axios from 'axios';

interface Image {
  id: number;
  title: string;
  category: string;
  file_path: string;
}

interface Friend {
  id: number;
  name: string;
  website_url: string | null;
  image_path: string;
  created_at: string;
}

interface PressItem {
  id: number;
  title: string;
  description: string;
  date: string;
  image_path: string;
  created_at: string;
}

export default function Dashboard() {
  const [images, setImages] = useState<Image[]>([]);
  const [friends, setFriends] = useState<Friend[]>([]);
  const [pressItems, setPressItems] = useState<PressItem[]>([]);
  const [folders, setFolders] = useState<PhotoFolder[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [activeTab, setActiveTab] = useState<'images' | 'friends' | 'press' | 'folders'>('images');
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuthStore();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/admin');
      return;
    }
    loadImages();
    loadFriends();
    loadPressItems();
    loadFolders();
  }, [isAuthenticated, navigate]);

  const loadImages = async () => {
    try {
      const response = await axios.get('/server/api/images.php');
      setImages(response.data);
    } catch (error) {
      console.error('Échec du chargement des images:', error);
    }
  };

  const loadFriends = async () => {
    try {
      const data = await fetchFriends();
      setFriends(data);
    } catch (error) {
      console.error('Échec du chargement des amis:', error);
    }
  };

  const loadPressItems = async () => {
    try {
      const data = await fetchPressItems();
      setPressItems(data);
    } catch (error) {
      console.error('Échec du chargement des articles de presse:', error);
    }
  };

  const loadFolders = async () => {
    try {
      const data = await fetchFolders();
      setFolders(data);
    } catch (error) {
      console.error('Échec du chargement des dossiers:', error);
    }
  };

  const handleDeleteImage = async (id: number) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
      return;
    }

    try {
      await axios.delete(`/server/api/images.php?id=${id}`);
      await loadImages();
    } catch (error) {
      console.error('Échec de la suppression de l\'image:', error);
    }
  };

  const handleAddFriend = async (formData: FormData) => {
    try {
      await addFriend(formData);
      await loadFriends();
    } catch (error) {
      throw new Error('Échec de l\'ajout de l\'ami');
    }
  };

  const handleDeleteFriend = async (id: number) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet ami ?')) {
      return;
    }

    try {
      await deleteFriend(id);
      await loadFriends();
    } catch (error) {
      console.error('Échec de la suppression de l\'ami:', error);
    }
  };

  const handleAddPressItem = async (formData: FormData) => {
    try {
      await addPressItem(formData);
      await loadPressItems();
    } catch (error) {
      throw new Error('Échec de l\'ajout de l\'article de presse');
    }
  };

  const handleEditPressItem = async (id: number, formData: FormData) => {
    try {
      await editPressItem(id, formData);
      await loadPressItems();
    } catch (error) {
      throw new Error('Échec de la modification de l\'article de presse');
    }
  };

  const handleDeletePressItem = async (id: number) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet article de presse ?')) {
      return;
    }

    try {
      await deletePressItem(id);
      await loadPressItems();
    } catch (error) {
      console.error('Échec de la suppression de l\'article de presse:', error);
    }
  };

  const handleAddFolder = async (name: string, description: string) => {
    try {
      await createFolder(name, description);
      await loadFolders();
    } catch (error) {
      throw new Error('Échec de la création du dossier');
    }
  };

  const handleEditFolder = async (id: number, name: string, description: string) => {
    try {
      await editFolder(id, name, description);
      await loadFolders();
    } catch (error) {
      throw new Error('Échec de la modification du dossier');
    }
  };

  const handleDeleteFolder = async (id: number) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce dossier ?')) {
      return;
    }

    try {
      await deleteFolder(id);
      await loadFolders();
    } catch (error) {
      console.error('Échec de la suppression du dossier:', error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/admin');
  };

  const filteredImages = selectedCategory === 'all' 
    ? images 
    : images.filter(image => image.category === selectedCategory);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <nav className="bg-black/50 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-xl font-bold text-white">Administration</h1>
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={handleLogout}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200"
              >
                <LogOut className="w-4 h-4 mr-2" />
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="mb-6 border-b border-gray-700">
            <nav className="-mb-px flex space-x-8">
              <button
                onClick={() => setActiveTab('images')}
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'images'
                    ? 'border-blue-500 text-blue-400'
                    : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'
                }`}
              >
                Images
              </button>
              <button
                onClick={() => setActiveTab('folders')}
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'folders'
                    ? 'border-blue-500 text-blue-400'
                    : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'
                }`}
              >
                Dossiers Photos
              </button>
              <button
                onClick={() => setActiveTab('friends')}
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'friends'
                    ? 'border-blue-500 text-blue-400'
                    : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'
                }`}
              >
                Liens Amis
              </button>
              <button
                onClick={() => setActiveTab('press')}
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  activeTab === 'press'
                    ? 'border-blue-500 text-blue-400'
                    : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'
                }`}
              >
                Revue de Presse
              </button>
            </nav>
          </div>

          {activeTab === 'images' && (
            <>
              <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10 mb-6">
                <h2 className="text-lg font-medium text-white mb-4">
                  Ajouter une nouvelle image
                </h2>
                <ImageUploadForm onUploadSuccess={loadImages} folders={folders} />
              </div>

              <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10">
                <h2 className="text-lg font-medium text-white mb-4">
                  Galerie d'images
                </h2>
                <CategorySelector 
                  selectedCategory={selectedCategory}
                  onCategoryChange={setSelectedCategory}
                />
                <ImageGallery images={filteredImages} onDelete={handleDeleteImage} />
              </div>
            </>
          )}

          {activeTab === 'folders' && (
            <>
              <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10 mb-6">
                <h2 className="text-lg font-medium text-white mb-4">
                  Créer un nouveau dossier photos
                </h2>
                <FolderForm onSubmit={handleAddFolder} />
              </div>

              <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10">
                <h2 className="text-lg font-medium text-white mb-4">
                  Liste des dossiers
                </h2>
                <FoldersList 
                  folders={folders} 
                  onDelete={handleDeleteFolder}
                  onEdit={handleEditFolder}
                />
              </div>
            </>
          )}

          {activeTab === 'friends' && (
            <>
              <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10 mb-6">
                <h2 className="text-lg font-medium text-white mb-4">
                  Ajouter un nouvel ami
                </h2>
                <FriendForm onSubmit={handleAddFriend} />
              </div>

              <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10">
                <h2 className="text-lg font-medium text-white mb-4">
                  Liste des amis
                </h2>
                <FriendsList friends={friends} onDelete={handleDeleteFriend} />
              </div>
            </>
          )}

          {activeTab === 'press' && (
            <>
              <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10 mb-6">
                <h2 className="text-lg font-medium text-white mb-4">
                  Ajouter un nouvel article de presse
                </h2>
                <PressForm onSubmit={handleAddPressItem} />
              </div>

              <div className="bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-md p-6 border border-white/10">
                <h2 className="text-lg font-medium text-white mb-4">
                  Liste des articles de presse
                </h2>
                <PressList 
                  pressItems={pressItems} 
                  onDelete={handleDeletePressItem}
                  onEdit={handleEditPressItem}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}