import axios from 'axios';
import { API_URL } from '../config';

export const uploadImage = async (formData: FormData) => {
    try {
        console.log('Starting upload...');
        console.log('FormData contents:', Array.from(formData.entries()));

        const response = await axios.post(`${API_URL}/upload.php`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log(`Upload Progress: ${percentCompleted}%`);
                }
            }
        });

        console.log('Upload response:', response.data);

        if (!response.data) {
            throw new Error('No response data received');
        }

        return response.data;
    } catch (error) {
        console.error('Upload error details:', error);
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(`Upload failed: ${error.response.data?.error || error.response.data?.message || error.message}`);
        }
        throw new Error('Upload failed: Network error');
    }
};