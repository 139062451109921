import React, { useRef } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { PressItem } from '../types/press';
import { motion } from 'framer-motion';

interface PressFlipBookProps {
  pressItems: PressItem[];
}

export default function PressFlipBook({ pressItems }: PressFlipBookProps) {
  const bookRef = useRef(null);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div className="max-w-5xl w-full aspect-[3/2] relative">
        <HTMLFlipBook
          width={550}
          height={733}
          size="stretch"
          minWidth={315}
          maxWidth={1000}
          minHeight={400}
          maxHeight={1533}
          showCover={true}
          mobileScrollSupport={true}
          className="shadow-2xl"
          ref={bookRef}
        >
          {/* Couverture */}
          <div className="relative bg-gray-900 p-8 rounded-r-lg shadow-md">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="h-full flex flex-col justify-center items-center text-center"
            >
              <h1 className="text-4xl font-bold text-white mb-4">Revue de Presse</h1>
              <p className="text-gray-300 text-lg">
                Collection d'articles et mentions dans la presse
              </p>
            </motion.div>
          </div>

          {/* Articles */}
          {pressItems.map((item, index) => (
            <div
              key={item.id}
              className="relative bg-white p-8 rounded shadow-md overflow-hidden"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="h-full"
              >
                <div className="absolute top-4 right-4 text-sm text-gray-500">
                  {formatDate(item.date)}
                </div>
                <div className="relative pt-[56.25%] mb-6">
                  <img
                    src={`/uploads/${item.image_path}`}
                    alt={item.title}
                    className="absolute inset-0 w-full h-full object-contain bg-gray-100 rounded-lg"
                  />
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4">{item.title}</h2>
                <div 
                  className="prose prose-sm max-w-none"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
                <div className="absolute bottom-4 right-4 text-sm text-gray-400">
                  Page {index + 1}
                </div>
              </motion.div>
            </div>
          ))}

          {/* Dernière page */}
          <div className="relative bg-gray-900 p-8 rounded-l-lg shadow-md">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="h-full flex flex-col justify-center items-center text-center"
            >
              <p className="text-gray-300 text-lg">
                Fin de la revue de presse
              </p>
            </motion.div>
          </div>
        </HTMLFlipBook>
      </div>
    </div>
  );
}