import React, { useMemo } from 'react';
import { Trash2, FolderOpen } from 'lucide-react';

interface Image {
  id: number;
  title: string;
  category: string;
  file_path: string;
  folder_id: number | null;
  folder_name: string | null;
}

interface ImageGalleryProps {
  images: Image[];
  onDelete: (id: number) => void;
}

const categoryColors = {
  infographies: 'bg-blue-900/50 border-blue-500/50',
  dessins: 'bg-green-900/50 border-green-500/50',
  peintures: 'bg-purple-900/50 border-purple-500/50',
  photos: 'bg-amber-900/50 border-amber-500/50',
  'travaux-pros': 'bg-rose-900/50 border-rose-500/50',
  immobilier: 'bg-cyan-900/50 border-cyan-500/50'
};

const categoryLabels = {
  infographies: 'Infographies',
  dessins: 'Dessins',
  peintures: 'Peintures',
  photos: 'Photos',
  'travaux-pros': 'Travaux Professionnels',
  immobilier: 'Immobilier'
};

export default function ImageGallery({ images, onDelete }: ImageGalleryProps) {
  const organizedImages = useMemo(() => {
    // Grouper d'abord par catégorie
    const byCategory = images.reduce((acc, image) => {
      if (!acc[image.category]) {
        acc[image.category] = {
          images: [],
          folders: {}
        };
      }
      
      if (image.category === 'photos' && image.folder_id && image.folder_name) {
        // Si c'est une photo avec un dossier, l'ajouter au dossier correspondant
        if (!acc[image.category].folders[image.folder_id]) {
          acc[image.category].folders[image.folder_id] = {
            name: image.folder_name,
            images: []
          };
        }
        acc[image.category].folders[image.folder_id].images.push(image);
      } else {
        // Pour les autres catégories ou les photos sans dossier
        acc[image.category].images.push(image);
      }
      
      return acc;
    }, {} as Record<string, { 
      images: Image[], 
      folders: Record<string, { name: string, images: Image[] }> 
    }>);

    return Object.entries(byCategory).sort(([a], [b]) => a.localeCompare(b));
  }, [images]);

  if (images.length === 0) {
    return (
      <div className="text-center py-12 text-gray-400">
        Aucune image n'a été uploadée pour le moment
      </div>
    );
  }

  const renderImages = (images: Image[]) => (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {images.map((image) => (
        <div key={image.id} className="relative group bg-gray-800/80 rounded-lg shadow-md overflow-hidden border border-white/10">
          <div className="relative pt-[75%]">
            <img
              src={`/uploads/${image.file_path}`}
              alt={image.title}
              className="absolute inset-0 w-full h-full object-contain bg-gray-900/50"
            />
            <button
              onClick={() => onDelete(image.id)}
              className="absolute top-2 right-2 p-2 bg-red-600 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
              title="Supprimer"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
          <div className="p-3">
            <h4 className="font-medium text-white truncate">
              {image.title}
            </h4>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="space-y-8">
      {organizedImages.map(([category, { images: categoryImages, folders }]) => (
        <div 
          key={category}
          className={`p-6 rounded-lg border ${categoryColors[category as keyof typeof categoryColors] || 'bg-gray-800/50 border-gray-600'}`}
        >
          <h3 className="text-lg font-semibold text-white mb-4">
            {categoryLabels[category as keyof typeof categoryLabels] || category}
            <span className="ml-2 text-sm font-normal text-gray-400">
              ({categoryImages.length + Object.values(folders).reduce((acc, folder) => acc + folder.images.length, 0)} images)
            </span>
          </h3>
          
          <div className="space-y-8">
            {/* Afficher d'abord les images sans dossier */}
            {category === 'photos' && categoryImages.length > 0 && (
              <div className="space-y-4">
                <h4 className="flex items-center text-lg font-medium text-white mb-4">
                  <FolderOpen className="w-5 h-5 mr-2 text-gray-400" />
                  Sans dossier
                  <span className="ml-2 text-sm font-normal text-gray-400">
                    ({categoryImages.length} images)
                  </span>
                </h4>
                {renderImages(categoryImages)}
              </div>
            )}

            {/* Afficher ensuite les dossiers et leurs images */}
            {category === 'photos' && Object.entries(folders).length > 0 && (
              <div className="space-y-8">
                {Object.entries(folders).map(([folderId, folder]) => (
                  <div key={folderId} className="bg-gray-800/80 rounded-lg p-6">
                    <h4 className="flex items-center text-lg font-medium text-white mb-4">
                      <FolderOpen className="w-5 h-5 mr-2 text-amber-400" />
                      {folder.name}
                      <span className="ml-2 text-sm font-normal text-gray-400">
                        ({folder.images.length} images)
                      </span>
                    </h4>
                    {renderImages(folder.images)}
                  </div>
                ))}
              </div>
            )}

            {/* Pour les autres catégories, afficher simplement les images */}
            {category !== 'photos' && renderImages(categoryImages)}
          </div>
        </div>
      ))}
    </div>
  );
}