import React from 'react';
import { Trash2, Link as LinkIcon } from 'lucide-react';
import { Friend } from '../../types/friend';

interface FriendsListProps {
  friends: Friend[];
  onDelete: (id: number) => void;
}

export default function FriendsList({ friends, onDelete }: FriendsListProps) {
  if (friends.length === 0) {
    return (
      <div className="text-center py-12 text-gray-400">
        Aucun ami n'a été ajouté pour le moment
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {friends.map((friend) => (
        <div
          key={friend.id}
          className="bg-gray-700/50 rounded-lg shadow-md overflow-hidden border border-gray-600"
        >
          <div className="aspect-w-16 aspect-h-9">
            <img
              src={`/uploads/${friend.image_path}`}
              alt={friend.name}
              className="object-cover w-full h-full"
            />
          </div>
          <div className="p-4">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium text-white">{friend.name}</h3>
              <div className="flex space-x-2">
                {friend.website_url && (
                  <a
                    href={friend.website_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 text-blue-400 hover:text-blue-300 transition-colors"
                  >
                    <LinkIcon className="w-5 h-5" />
                  </a>
                )}
                <button
                  onClick={() => onDelete(friend.id)}
                  className="p-2 text-red-400 hover:text-red-300 transition-colors"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}