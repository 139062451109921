import React from 'react';
import { Download } from 'lucide-react';
import { Message, downloadConversation } from '../utils/exportConversation';

interface ExportButtonProps {
  messages: Message[];
}

export default function ExportButton({ messages }: ExportButtonProps) {
  return (
    <button
      onClick={() => downloadConversation(messages)}
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <Download className="w-4 h-4 mr-2" />
      Exporter la conversation
    </button>
  );
}