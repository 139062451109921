import React, { useState } from 'react';
import { Trash2, Edit2 } from 'lucide-react';
import { PressItem } from '../../types/press';
import EditPressForm from './EditPressForm';
import ImageZoom from '../ImageZoom';

interface PressListProps {
  pressItems: PressItem[];
  onDelete: (id: number) => void;
  onEdit: (id: number, formData: FormData) => Promise<void>;
}

export default function PressList({ pressItems, onDelete, onEdit }: PressListProps) {
  const [editingId, setEditingId] = useState<number | null>(null);

  if (pressItems.length === 0) {
    return (
      <div className="text-center py-12 text-gray-400">
        Aucun article de presse n'a été ajouté pour le moment
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-6">
      {pressItems.map((item) => (
        <div
          key={item.id}
          className="bg-gray-700/50 rounded-lg shadow-md overflow-hidden border border-gray-600"
        >
          {editingId === item.id ? (
            <div className="p-6">
              <EditPressForm
                pressItem={item}
                onSubmit={async (id, formData) => {
                  await onEdit(id, formData);
                  setEditingId(null);
                }}
                onCancel={() => setEditingId(null)}
              />
            </div>
          ) : (
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2 relative">
                <div className="relative pt-[75%]">
                  <img
                    src={`/uploads/${item.image_path}`}
                    alt={item.title}
                    className="absolute inset-0 w-full h-full object-contain bg-gray-800/50"
                  />
                  <ImageZoom src={`/uploads/${item.image_path}`} alt={item.title} />
                </div>
              </div>
              <div className="p-6 md:w-1/2 flex flex-col justify-between">
                <div>
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-xl font-bold text-white">{item.title}</h3>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => setEditingId(item.id)}
                        className="p-2 text-blue-400 hover:text-blue-300 transition-colors"
                        title="Modifier"
                      >
                        <Edit2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => onDelete(item.id)}
                        className="p-2 text-red-400 hover:text-red-300 transition-colors"
                        title="Supprimer"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  <div className="prose prose-invert max-w-none">
                    {item.description}
                  </div>
                </div>
                <div className="text-sm text-gray-400 mt-4">
                  {new Date(item.date).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}