import { useState, useEffect } from 'react';
import { Image } from '../types/image';
import { fetchImages, fetchImagesByCategory, fetchCarouselImages } from '../services/api/images';

interface UseImagesResult {
  images: Image[];
  loading: boolean;
  error: string | null;
}

export function useImages(): UseImagesResult {
  const [images, setImages] = useState<Image[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    const loadImages = async () => {
      try {
        const data = await fetchImages();
        if (mounted) {
          setImages(data);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to load images');
          setImages([]);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadImages();

    return () => {
      mounted = false;
    };
  }, []);

  return { images, loading, error };
}

export function useCarouselImages(limit: number = 10): UseImagesResult {
  const [images, setImages] = useState<Image[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    const loadImages = async () => {
      try {
        const data = await fetchCarouselImages(limit);
        if (mounted) {
          setImages(data);
          setError(null);
        }
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to load carousel images');
          setImages([]);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadImages();

    return () => {
      mounted = false;
    };
  }, [limit]);

  return { images, loading, error };
}