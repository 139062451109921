import axios from 'axios';
import { API_URL } from '../config';
import { PhotoFolder } from '../../types/folder';

export const fetchFolders = async (): Promise<PhotoFolder[]> => {
  try {
    const response = await axios.get(`${API_URL}/folders.php`);
    return response.data;
  } catch (error) {
    console.error('Error fetching folders:', error);
    throw error;
  }
};

export const createFolder = async (name: string, description?: string): Promise<PhotoFolder> => {
  try {
    const response = await axios.post(`${API_URL}/folders.php`, { name, description });
    return response.data;
  } catch (error) {
    console.error('Error creating folder:', error);
    throw error;
  }
};

export const editFolder = async (id: number, name: string, description: string): Promise<void> => {
  try {
    await axios.put(`${API_URL}/folders.php?id=${id}`, { name, description });
  } catch (error) {
    console.error('Error editing folder:', error);
    throw error;
  }
};

export const deleteFolder = async (id: number): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/folders.php?id=${id}`);
  } catch (error) {
    console.error('Error deleting folder:', error);
    throw error;
  }
};