import React, { useState, useEffect } from 'react';
import { Upload, Loader } from 'lucide-react';
import { uploadImage } from '../../services/api/upload';
import BeforeAfterPreview from './BeforeAfterPreview';
import { PhotoFolder } from '../../types/folder';

interface ImageUploadFormProps {
  onUploadSuccess: () => void;
  folders?: PhotoFolder[];
}

export default function ImageUploadForm({ onUploadSuccess, folders = [] }: ImageUploadFormProps) {
  const [isUploading, setIsUploading] = useState(false);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('infographies');
  const [folderId, setFolderId] = useState<string>('');
  const [beforeFile, setBeforeFile] = useState<File | null>(null);
  const [afterFile, setAfterFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [beforePreview, setBeforePreview] = useState<string | null>(null);
  const [afterPreview, setAfterPreview] = useState<string | null>(null);

  const categories = [
    { id: 'infographies', label: 'Infographies' },
    { id: 'dessins', label: 'Dessins' },
    { id: 'peintures', label: 'Peintures' },
    { id: 'photos', label: 'Photos' },
    { id: 'travaux-pros', label: 'Travaux pros' },
    { id: 'immobilier', label: 'Immobilier' }
  ];

  useEffect(() => {
    return () => {
      if (beforePreview) URL.revokeObjectURL(beforePreview);
      if (afterPreview) URL.revokeObjectURL(afterPreview);
    };
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'before' | 'after') => {
    const file = e.target.files?.[0];
    if (file) {
      if (type === 'before') {
        setBeforeFile(file);
        if (beforePreview) URL.revokeObjectURL(beforePreview);
        setBeforePreview(URL.createObjectURL(file));
      } else {
        setAfterFile(file);
        if (afterPreview) URL.revokeObjectURL(afterPreview);
        setAfterPreview(URL.createObjectURL(file));
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (category === 'immobilier' && (!beforeFile || !afterFile)) {
      setError('Pour la catégorie Immobilier, vous devez sélectionner une image avant et après');
      return;
    } else if (category !== 'immobilier' && !beforeFile) {
      setError('Veuillez sélectionner un fichier');
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('category', category);
      
      if (category === 'photos' && folderId) {
        formData.append('folder_id', folderId);
      }
      
      if (category === 'immobilier') {
        formData.append('before_image', beforeFile);
        formData.append('after_image', afterFile);
      } else {
        formData.append('image', beforeFile);
      }

      const response = await uploadImage(formData);

      if (response.success) {
        setTitle('');
        setBeforeFile(null);
        setAfterFile(null);
        setBeforePreview(null);
        setAfterPreview(null);
        setFolderId('');
        onUploadSuccess();
      } else {
        setError(response.error || 'Une erreur est survenue');
      }
    } catch (err) {
      console.error('Upload error:', err);
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-200">
          Titre de l'image
        </label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-200">
          Catégorie
        </label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id} className="bg-gray-700 text-white">
              {cat.label}
            </option>
          ))}
        </select>
      </div>

      {category === 'photos' && folders.length > 0 && (
        <div>
          <label className="block text-sm font-medium text-gray-200">
            Dossier (optionnel)
          </label>
          <select
            value={folderId}
            onChange={(e) => setFolderId(e.target.value)}
            className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Aucun dossier</option>
            {folders.map((folder) => (
              <option key={folder.id} value={folder.id} className="bg-gray-700 text-white">
                {folder.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-200 mb-2">
            {category === 'immobilier' ? 'Image "Avant"' : 'Image'}
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md bg-gray-800/50 hover:bg-gray-800 transition-colors duration-200">
            <div className="space-y-1 text-center">
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-400">
                <label className="relative cursor-pointer rounded-md font-medium text-blue-400 hover:text-blue-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                  <span>Sélectionner un fichier</span>
                  <input
                    type="file"
                    className="sr-only"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, 'before')}
                  />
                </label>
              </div>
              {beforeFile && (
                <p className="text-sm text-gray-400">
                  Fichier sélectionné: {beforeFile.name}
                </p>
              )}
            </div>
          </div>
        </div>

        {category === 'immobilier' && (
          <div>
            <label className="block text-sm font-medium text-gray-200 mb-2">
              Image "Après"
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md bg-gray-800/50 hover:bg-gray-800 transition-colors duration-200">
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-400">
                  <label className="relative cursor-pointer rounded-md font-medium text-blue-400 hover:text-blue-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                    <span>Sélectionner un fichier</span>
                    <input
                      type="file"
                      className="sr-only"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, 'after')}
                    />
                  </label>
                </div>
                {afterFile && (
                  <p className="text-sm text-gray-400">
                    Fichier sélectionné: {afterFile.name}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {category === 'immobilier' && beforePreview && afterPreview && (
          <div className="mt-4">
            <h3 className="text-lg font-medium text-gray-200 mb-2">Prévisualisation</h3>
            <BeforeAfterPreview
              beforeImage={beforePreview}
              afterImage={afterPreview}
            />
          </div>
        )}
      </div>

      {error && (
        <div className="text-red-400 text-sm mt-2">
          {error}
        </div>
      )}

      <button
        type="submit"
        disabled={isUploading}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-600 disabled:cursor-not-allowed transition-colors duration-200"
      >
        {isUploading ? (
          <Loader className="w-5 h-5 animate-spin" />
        ) : (
          'Télécharger l\'image'
        )}
      </button>
    </form>
  );
}