import React, { useState } from 'react';
import { useImages } from '../hooks/useImages';
import ImageCarousel from '../components/ImageCarousel';
import ImageModal from '../components/ImageModal';
import { X } from 'lucide-react';

export default function Home() {
  const { images: allImages, loading: isLoading, error } = useImages();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [hiddenImages, setHiddenImages] = useState<number[]>([]);

  // Filtrer uniquement les peintures et les dessins, puis prendre les 2 plus récents
  const latestImages = allImages
    .filter(image => 
      (image.category === 'peintures' || image.category === 'dessins') &&
      !hiddenImages.includes(image.id)
    )
    .sort((a, b) => {
      const dateA = new Date(a.created_at || 0).getTime();
      const dateB = new Date(b.created_at || 0).getTime();
      return dateB - dateA;
    })
    .slice(0, 2);

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handleNext = () => {
    if (selectedImageIndex !== null && selectedImageIndex < latestImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const handlePrev = () => {
    if (selectedImageIndex !== null && selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const handleHideImage = (imageId: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setHiddenImages(prev => [...prev, imageId]);
  };

  return (
    <div className="max-w-7xl mx-auto">
      <div className="relative">
        <ImageCarousel />
        <div className="absolute inset-0 flex flex-col justify-center z-10">
          <div className="p-8 sm:p-12">
            <h1 
              className="text-5xl sm:text-6xl font-dali tracking-wider text-white transform -rotate-3"
              style={{ 
                textShadow: '0 0 15px rgba(255, 255, 255, 0.1), 0 0 30px rgba(255, 255, 255, 0.1)',
                marginLeft: '2rem'
              }}
            >
              Thierry Chabenat
            </h1>
            <p 
              className="text-2xl text-white transform -rotate-1"
              style={{ 
                fontFamily: 'Maiandra GD, Arial, sans-serif',
                marginLeft: '210px',
                marginTop: '-10px'
              }}
            >
              Artiste vachement balèze
            </p>
          </div>
        </div>
      </div>

      <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-gray-800 rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-6 text-white">Derniers Ajouts</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {isLoading ? (
              <div className="col-span-2 flex justify-center items-center h-48">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-400"></div>
              </div>
            ) : error ? (
              <div className="col-span-2 text-center text-red-400 py-4">
                {error}
              </div>
            ) : latestImages.length > 0 ? (
              latestImages.map((image, index) => (
                <div 
                  key={image.id} 
                  className="relative group cursor-pointer"
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={`/uploads/${image.file_path}`}
                    alt={image.title}
                    className="w-full aspect-[4/3] object-cover rounded-lg shadow-sm transition-transform duration-300 group-hover:scale-105"
                  />
                  <button
                    onClick={(e) => handleHideImage(image.id, e)}
                    className="absolute top-2 right-2 p-1.5 bg-black/50 text-white rounded-full opacity-0 group-hover:opacity-100 hover:bg-black/75 transition-all duration-200 z-10"
                    title="Masquer l'image"
                  >
                    <X className="w-4 h-4" />
                  </button>
                  <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/70 to-transparent">
                    <p className="text-white text-lg font-medium">{image.title}</p>
                    <p className="text-white/80 text-sm capitalize">
                      {image.category === 'peintures' ? 'Peinture' : 'Dessin'}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="col-span-2 text-center text-gray-300">
                Aucune peinture ou dessin récent à afficher
              </p>
            )}
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-4 text-white">Dans la Presse</h2>
          <div className="space-y-4">
            <div className="border-l-4 border-blue-500 pl-4">
              <p className="text-gray-200 italic">
                "Un mélange magistral de techniques traditionnelles et de vision moderne..."
              </p>
              <p className="text-sm text-gray-400 mt-2">- Magazine Art Hebdo</p>
            </div>
            <div className="border-l-4 border-blue-500 pl-4">
              <p className="text-gray-200 italic">
                "L'un des artistes les plus prometteurs de la décennie..."
              </p>
              <p className="text-sm text-gray-400 mt-2">- Revue Créative</p>
            </div>
          </div>
        </div>
      </div>

      {selectedImageIndex !== null && (
        <ImageModal
          src={`/uploads/${latestImages[selectedImageIndex].file_path}`}
          alt={latestImages[selectedImageIndex].title}
          onClose={() => setSelectedImageIndex(null)}
          onNext={handleNext}
          onPrev={handlePrev}
          hasNext={selectedImageIndex < latestImages.length - 1}
          hasPrev={selectedImageIndex > 0}
        />
      )}
    </div>
  );
}