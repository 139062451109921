import React from 'react';

export default function ImageCarousel() {
  return (
    <div className="relative w-full h-[60vh] overflow-hidden rounded-lg">
      <img
        src="https://thierrychabenat.fr/images/carroussel1.jpg"
        alt="Art Gallery Banner"
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-r from-black/60 to-transparent" />
    </div>
  );
}